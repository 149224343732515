import React from 'react';
import { Text, View, Image, Platform } from 'react-native';

import styles from './ServerFullStyle';

export default function ServerFull() {
  return (
    <View style={[styles.container]}>
      <Image
        source={{ uri: 'https://pbs.twimg.com/media/FekBJcgWYAEFXzd?format=jpg&name=medium' }}
        style={styles.image}
      />
      {/* @ts-ignore */}
      <Text style={[styles.text, styles.title]}>Nos serveurs sont complets !</Text>
      <Text style={[styles.text]}>
        sQuiz est un jeu entièrement gratuit, notre capacité d'accueil est limitée.
      </Text>
      <Text style={[styles.text]}>
        Un streamer est sûrement en live sur le jeu, tu peux vérifier ça en allant dans la catégorie{' '}
        <Text
          onPress={() => window.open('https://www.twitch.tv/directory/game/sQuiz', '_blank')}
          style={[styles.text, styles.link]}
          {...Platform.select({
            web: {
              accessibilityRole: 'link',
            },
          })}>
          sQuiz sur Twitch
        </Text>{' '}
        !
      </Text>
      <Text style={[styles.text]}>
        Si tu veux en savoir plus sur sQuiz tu peux rejoindre notre{' '}
        <Text
          onPress={() => window.open('https://discord.com/invite/xssGz5xu3e', '_blank')}
          style={[styles.text, styles.link]}
          {...Platform.select({
            web: {
              accessibilityRole: 'link',
            },
          })}>
          discord
        </Text>{' '}
        !
      </Text>
    </View>
  );
}
