import Medal from '@Src/components/Medal';
import playerRankState from '@Src/global/Room/playerRanks';
import React from 'react';
import { View } from 'react-native';
import { useRecoilValue } from 'recoil';

import styles from './RoundCounterStyle';

export default function RoundCounter() {
  const ranks = useRecoilValue(playerRankState);

  return (
    <View style={styles.container}>
      {ranks.map((rank, value) => (
        <Medal key={value} rank={rank} />
      ))}
    </View>
  );
}
