import Badge from '@Src/components/Badge';
import { useTheme } from '@react-navigation/native';
import { BadgeTypes } from '@squiz/shared';
import React from 'react';
import { View, Pressable } from 'react-native';

import styles from './LockedBadgeStyle';

export default function LockedBadge({
  selected = false,
  lock = false,
  onPress,
  name,
  source,
  lockedDescription,
  type,
  animated = false,
}: {
  lock?: boolean;
  selected?: boolean;
  onPress: () => void;
  name: string;
  source: string;
  lockedDescription?: string;
  type: BadgeTypes;
  animated?: boolean;
}) {
  const { colors } = useTheme();

  if (lock) {
    return (
      <View style={styles.containerStyle}>
        <Badge
          locked
          badgeName={name}
          source={source}
          imageStyle={styles.pictureWidth}
          overForInfo
          lockedDescription={lockedDescription}
          type={type}
          animated={animated}
        />
      </View>
    );
  }
  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.containerStyle,
        {
          backgroundColor: selected ? colors.notification : 'none',
        },
      ]}>
      <Badge
        badgeName={name}
        source={source}
        type={type}
        imageStyle={styles.pictureWidth}
        overForInfo
        animated={animated}
      />
    </Pressable>
  );
}
