import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    color: 'white',
  },
});

export default styles;
