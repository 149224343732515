import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 300,
  },
  chart: {
    height: 300,
  },
});

export default styles;
