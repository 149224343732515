import { useProfileDropDownRowStyle } from '@Src/components/Header/Profile/ProfileStyle';
import { HoverStyle } from '@Src/components/Hover';
import Text from '@Src/components/Text';
import userState from '@Src/global/userState';
import { removeInStore, StorageEnum } from '@Src/utils/storage';
import { get } from '@Src/utils/wrappedFetch';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation, useTheme } from '@react-navigation/native';
import React from 'react';
import { Pressable, View } from 'react-native';
import { useRecoilState } from 'recoil';

export default function DisconnectButton() {
  const [user, setUser] = useRecoilState(userState);
  const navigation = useNavigation();
  const { colors } = useTheme();
  const dropDownStyle = useProfileDropDownRowStyle();

  async function disconnect() {
    if (user.token) {
      get({ path: 'logout', token: user.token });
    }
    await removeInStore(StorageEnum.User);
    setUser({
      username: null,
      token: null,
      connected: false,
      privateCode: null,
      staff: false,
    });
    navigation.navigate('Home');
  }

  return (
    <HoverStyle onHover={dropDownStyle.rowHover}>
      <View style={dropDownStyle.rowWrapper}>
        <Pressable onPress={disconnect} style={dropDownStyle.dropDownRow}>
          <FontAwesome5 name="door-open" size={15} color={colors.text} />
          <Text style={dropDownStyle.dropDownText}>Déconnexion</Text>
        </Pressable>
      </View>
    </HoverStyle>
  );
}
