import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
    paddingBottom: 20,
    paddingTop: 50,
  },
});
export default styles;
