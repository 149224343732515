import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  topItem: {
    paddingHorizontal: 5,
    paddingVertical: 8,
    borderRadius: 5,
    marginVertical: 4,
  },
});

export default styles;
