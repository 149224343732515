import userBasicInfoState from '@Src/global/userBasicInfos';
import userState from '@Src/global/userState';
import { ShowBadge } from '@squiz/shared';
import React from 'react';
import { View } from 'react-native';
import { useRecoilValue } from 'recoil';

import LockedBadge from '../../LockedBadge/';
import { DisplayBadge } from '../Badges';

type RewardBadgesProps = {
  handlePress: (badgeId: ShowBadge) => void;
  badges: DisplayBadge[];
};

export default function RewardBadges({ handlePress, badges }: RewardBadgesProps) {
  const user = useRecoilValue(userState);
  const userBasicInfos = useRecoilValue(userBasicInfoState);

  if (!user || !userBasicInfos) return null;

  return (
    <>
      {badges.map((reward) => (
        <View key={reward.id}>
          <LockedBadge
            onPress={() => handlePress(reward)}
            selected={userBasicInfos.badge?.id === reward.id}
            name={reward.title}
            source={reward.source}
            type={reward.type}
            animated={reward.animated}
            lock={!reward.unlocked}
            lockedDescription={reward.description}
          />
        </View>
      ))}
    </>
  );
}
