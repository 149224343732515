import Card from '@Src/components/Card';
import { CenterContainer } from '@Src/components/Containers';
import Text from '@Src/components/Text';
import userState from '@Src/global/userState';
import { refreshToken } from '@Src/utils/twitch';
import { get } from '@Src/utils/wrappedFetch';
import { EmoteProps, TwitchInfo } from '@squiz/shared';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, Image } from 'react-native';
import { useRecoilValue } from 'recoil';

import CustomReward from './CustomReward';
import useStatsStyle from './StreamerStyle';
import Sub from './Sub';

type TwitchStreamer = {
  id: string;
  login: string;
  display_name: string;
  type: 'staff' | 'admin' | 'global_mod' | '';
  broadcaster_type: 'partner' | 'affiliate' | '';
  description: string;
  profile_image_url: string;
  offline_image_url: string;
  view_count: number;
  email: string;
  created_at: string;
};

type TwitchStreamers = {
  data: TwitchStreamer[];
};

export default function Streamer() {
  const user = useRecoilValue(userState);
  const [twitchToken, setTwitchToken] = useState<TwitchInfo>();
  const [emotes, setEmotes] = useState<EmoteProps>();
  const [streamerInfos, setStreamerInfos] = useState<TwitchStreamer>();

  const styles = useStatsStyle();

  async function fetchEmotes() {
    if (!twitchToken || !user.token) return;
    try {
      const url = `${process.env.TWITCH_API_URL}/chat/emotes?broadcaster_id=${twitchToken.twitchId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${twitchToken.twitchToken}`,
          'Client-Id': process.env.TWITCH_CLIENT_ID!,
          'content-type': 'application/json',
        },
      });
      if (response.status === 401) {
        const newToken = await refreshToken(user.token);
        if (newToken) {
          setTwitchToken({ ...twitchToken, twitchToken: newToken.token });
        }
      } else if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        const result = (await response.json()) as EmoteProps;
        setEmotes(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchStreamerInfos() {
    if (!twitchToken || !user.token) return;
    try {
      const url = `${process.env.TWITCH_API_URL}/users?id=${twitchToken.twitchId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${twitchToken.twitchToken}`,
          'Client-Id': process.env.TWITCH_CLIENT_ID!,
          'content-type': 'application/json',
        },
      });
      const result = (await response.json()) as TwitchStreamers;
      setStreamerInfos(result.data[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTwitchToken() {
    if (!user.token) return;
    try {
      const twitchToken = await get<TwitchInfo>({ path: 'twitch-token', token: user.token });
      if (!twitchToken) return;
      setTwitchToken(twitchToken);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchEmotes();
    fetchStreamerInfos();
  }, [twitchToken, user]);

  useEffect(() => {
    fetchTwitchToken();
  }, [user]);

  if (!emotes || !twitchToken || !streamerInfos) {
    return (
      <CenterContainer>
        <ActivityIndicator />
      </CenterContainer>
    );
  }

  return (
    <View style={styles.container}>
      <Card>
        <View style={styles.profilePicContainer}>
          <Image source={{ uri: streamerInfos.profile_image_url }} style={styles.profilePic} />
        </View>
        <View style={styles.panelInfoContainer}>
          <Text style={styles.separator}>
            Bienvenue sur ton panel streamer sQuiz {streamerInfos.display_name} !
          </Text>
          <Text>
            Depuis ce panel tu peux accéder à différentes fonctionnalités mise en place par sQuiz
            pour les streamers.
          </Text>
        </View>
        <Sub emotes={emotes} />
        <CustomReward emotes={emotes} twitchToken={twitchToken} />
      </Card>
    </View>
  );
}
