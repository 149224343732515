import { ImageSourcePropType } from 'react-native';

const staff: {
  name: string;
  description: string;
  avatar: ImageSourcePropType;
}[] = [
  {
    name: 'Xari',
    description: 'Fondateur',
    avatar: require('@Assets/images/staff/xari.png'),
  },
  {
    name: 'Maji',
    description: 'Développeur principal',
    avatar: require('@Assets/images/staff/maji.png'),
  },
  {
    name: 'LECARRELAGEDUMARRIAGE',
    description: 'Nick Fury',
    avatar: require('@Assets/images/staff/lecarrelage.png'),
  },
  {
    name: 'Epsi',
    description: 'Rédacteur & Correcteur',
    avatar: require('@Assets/images/staff/epsi.png'),
  },
  {
    name: 'Wolfiriane',
    description: 'Rédactrice & Correctrice',
    avatar: require('@Assets/images/staff/wolfiriane.png'),
  },
  {
    name: 'Worazme',
    description: 'Gestionnaire des questions',
    avatar: require('@Assets/images/staff/worazme.png'),
  },
  {
    name: 'Scottsbury',
    description: 'Rédacteur & Correcteur',
    avatar: require('@Assets/images/staff/scottbury.png'),
  },
  {
    name: 'LEMIBANDDEXARI',
    description: 'DRH',
    avatar: require('@Assets/images/staff/mimi.png'),
  },
  {
    name: 'Kerri',
    description: 'Content Creator & Correctrice',
    avatar: require('@Assets/images/staff/kerri.png'),
  },
  {
    name: 'Kamusashi',
    description: 'Rédacteur & Correcteur',
    avatar: require('@Assets/images/staff/kamusashi.png'),
  },
  {
    name: 'Soldja',
    description: 'Content Creator & Correcteur',
    avatar: require('@Assets/images/staff/soldja.png'),
  },
  {
    name: 'LESLUNETTESDEXARI',
    description: 'Graphiste',
    avatar: require('@Assets/images/staff/leslunettes.png'),
  },
  {
    name: 'Dagger',
    description: 'Développeur',
    avatar: require('@Assets/images/staff/dagger.png'),
  },
  {
    name: 'AurelMrc',
    description: 'Développeur',
    avatar: require('@Assets/images/staff/aurelmrc.png'),
  },
];

export default staff;
