import { useTheme } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    paddingTop: 5,
  },
  level: {
    paddingRight: 5,
  },
  avatar: {
    width: 23,
    height: 23,
    marginRight: 5,
  },
  dropDownContainer: {
    position: 'absolute',
    backgroundColor: '#111421',
    borderRadius: 8,
    borderWidth: 2,
    top: 30,
    right: 0,
    shadowOffset: {
      width: -2,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 2,
  },
  dropDown: {
    marginHorizontal: 5,
    marginVertical: 5,
  },
});

// Shared top right buttons styles
export function useHeaderButtonStyle() {
  const { colors } = useTheme();

  return StyleSheet.create({
    shared: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 4,
      paddingLeft: 4,
    },
    button: {
      backgroundColor: 'none',
    },
    buttonClicked: {
      backgroundColor: colors.border,
      borderRadius: 10,
    },
  });
}

// Shared profile dropdown styles
export function useProfileDropDownRowStyle() {
  const { colors } = useTheme();

  return StyleSheet.create({
    dropDownRow: {
      flexDirection: 'row',
      paddingVertical: 4,
      alignItems: 'center',
    },
    dropDownText: {
      paddingLeft: 10,
      fontWeight: 'bold',
    },
    rowHover: {
      backgroundColor: colors.background,
      borderRadius: 5,
    },
    rowWrapper: {
      paddingHorizontal: 5,
    },
  });
}

export default styles;
