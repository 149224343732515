const Avatars = {
  '1': 0,
  '2': 0,
  '3': 0,
  '4': 0,
  '5': 5,
  '6': 5,
  '7': 10,
  '8': 10,
  '9': 15,
  '10': 15,
  '11': 20,
  '12': 20,
  '13': 25,
  '14': 25,
  '15': 30,
  '16': 30,
  '17': 35,
  '18': 35,
  '19': 40,
  '20': 40,
  '21': 45,
  '22': 45,
  '23': 50,
  '24': 50,
  '25': 60,
  '26': 60,
  '27': 65,
  '28': 65,
  '29': 70,
  '30': 75,
};

export default Avatars;
