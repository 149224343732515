import { useScreenWidth } from '@Src/utils/hooks/screenWidth';
import responsive from '@Src/utils/responsive';
import { useTheme } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

export default function useStreamStyle() {
  const screenWidth = useScreenWidth();
  const { colors } = useTheme();

  return StyleSheet.create({
    scrollView: {
      maxHeight: 450,
    },
    streamsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    streamCard: {
      paddingBottom: responsive(screenWidth, 10, 10, 10),
      paddingHorizontal: responsive(screenWidth, 5, 5, 2),
    },
    cardBorder: {
      borderRadius: 5,
      borderColor: colors.border,
      borderWidth: 2,
    },
    cardHover: {
      borderColor: 'white',
    },
    cardViewerCount: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
      alignItems: 'center',
      marginTop: 5,
      marginLeft: 5,
      paddingHorizontal: 3,
      paddingVertical: 2,
      backgroundColor: 'rgba(20, 20, 20, 0.9)',
      borderRadius: 3,
    },
    cardViewerCountText: {
      paddingLeft: 5,
      color: 'rgb(240, 240, 240)',
    },
    image: {
      width: 180,
      height: 101,
    },
    text: {
      fontWeight: 'bold',
      textAlign: 'center',
      color: colors.text,
    },
  });
}
