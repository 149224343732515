import Timer from '@Src/components/Timer';
import roomInfosState from '@Src/global/Room/roomInfos';
import roomStatusState from '@Src/global/Room/roomStatus';
import isQuestionTimeState from '@Src/global/isQuestionTimeState';
import timerState from '@Src/global/timerState';
import { RoomStatus } from '@squiz/shared';
import React from 'react';
import { useRecoilValue } from 'recoil';

export default function GameTimer() {
  const originalTime = useRecoilValue(timerState);
  const roomInfos = useRecoilValue(roomInfosState);
  const roomStatus = useRecoilValue(roomStatusState);
  const isQuestionTime = useRecoilValue(isQuestionTimeState);

  if (roomStatus.status === RoomStatus.Waiting) {
    return null;
  }
  // If the round is Manually started and not in Starting and not in Resume, we don't want to display the timer
  if (
    roomInfos?.startRoundManually &&
    !isQuestionTime &&
    roomStatus.status !== RoomStatus.Starting &&
    roomStatus.status !== RoomStatus.ResumeRound
  ) {
    return null;
  }

  return <Timer time={originalTime} size={40} strokeWidth={3} refresh={!isQuestionTime} />;
}
