import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#252C4A',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    paddingHorizontal: 10,
  },
  text: {
    color: 'white',
    textAlign: 'center',
    marginVertical: 2,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginVertical: 20,
  },
  link: {
    fontWeight: 'bold',
  },
  image: {
    width: 200,
    height: 300,
  },
});

export default styles;
