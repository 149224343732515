import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: {
    padding: 5,
    margin: 15,
    borderRadius: 5,
  },
  pictureWidth: {
    width: 50,
    height: 50,
  },
});

export default styles;
