import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    height: 45,
    overflowY: 'auto',
    overflowX: 'auto',
  },
});

export default styles;
