import Text from '@Src/components/Text';
import userBasicInfoState from '@Src/global/userBasicInfos';
import userState from '@Src/global/userState';
import { get, put } from '@Src/utils/wrappedFetch';
import { BadgeTypes, ShowBadge, ShowBadges } from '@squiz/shared';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useRecoilState, useRecoilValue } from 'recoil';

import LockedBadge from '../LockedBadge/LockedBadge';
import styles from './BadgeStyle';
import RewardBadges from './RewardBadges';
import SubBadges from './SubBadges';

export type DisplayBadge = ShowBadge & { unlocked: boolean };

export type EditBadge = { type: BadgeTypes; id: number };

export default function Badges() {
  const [userBasicInfos, setUserBasicInfos] = useRecoilState(userBasicInfoState);
  const user = useRecoilValue(userState);
  const [badges, setBadges] = useState<DisplayBadge[]>([]);
  const subBadges = badges.filter(({ type }) => type === BadgeTypes.Sub);
  const rewardBadges = badges.filter(({ type }) => type === BadgeTypes.Reward);
  const specialBadges = badges.filter(({ type }) => type === BadgeTypes.Special);

  async function fetchBadges() {
    if (!user.token) return;
    try {
      const response = await get<ShowBadges>({ path: 'badges/show', token: user.token });
      const badges = response.badges
        .map((badge) => ({
          ...badge,
          unlocked: !!response.userBadges.find(({ badgeId }) => badge.id === badgeId),
        }))
        .sort(({ id: idA }, { id: idB }) => idA - idB);
      setBadges(badges);
    } catch (error) {}
  }

  useEffect(() => {
    fetchBadges();
  }, [user]);

  if (!user || !userBasicInfos) return null;

  function onPress(badge: ShowBadge) {
    if (!userBasicInfos || !user.token) return;
    setUserBasicInfos({ ...userBasicInfos, badge });
    try {
      put({
        path: 'me-edit',
        token: user.token,
        body: { badge: { type: badge.type, id: badge.id } },
      });
    } catch (error) {
      console.error(error);
    }
  }

  if (!userBasicInfos) return null;

  return (
    <>
      {subBadges.length > 0 && (
        <>
          <Text fontFamily="title" fontSize="xxl">
            BADGES SUB
          </Text>
          <View style={styles.container}>
            <SubBadges handlePress={onPress} badges={subBadges} />
          </View>
        </>
      )}
      {rewardBadges.length > 0 && (
        <>
          <Text fontFamily="title" fontSize="xxl">
            BADGES DE RÉCOMPENSES
          </Text>
          <View style={styles.container}>
            <RewardBadges handlePress={onPress} badges={rewardBadges} />
          </View>
        </>
      )}
      {specialBadges.length > 0 && (
        <>
          <Text fontFamily="title" fontSize="xxl">
            BADGES SPÉCIAUX
          </Text>
          <View style={styles.container}>
            {specialBadges.map((special) => {
              return (
                <View key={special.id}>
                  <LockedBadge
                    onPress={() => onPress(special)}
                    selected={userBasicInfos.badge?.id === special.id}
                    name={special.title}
                    source={special.source}
                    lock={!special.unlocked}
                    type={special.type}
                    animated={special.animated}
                  />
                </View>
              );
            })}
          </View>
        </>
      )}
    </>
  );
}
