import { IsHover } from '@Src/components/Hover';
import { useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { Pressable, Image, StyleProp, ImageStyle, ViewStyle, View } from 'react-native';

import styles from './EmoteStyle';

export default function Emote({
  uri,
  onPress,
  selected = false,
  pictureStyle,
  containerStyle,
}: {
  uri: { static: string; animated?: string };
  onPress?: () => void;
  selected?: boolean;
  pictureStyle?: StyleProp<ImageStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}) {
  const [animate, setAnimate] = useState(false);
  const { colors } = useTheme();

  function handleAnimate() {
    setAnimate(true);
  }

  function handleLeaveAnimate() {
    setAnimate(false);
  }

  const content = uri.animated ? (
    <IsHover onHover={handleAnimate} onLeaveOver={handleLeaveAnimate}>
      {animate && (
        <Image source={{ uri: uri.animated }} style={[styles.pictureWidth, pictureStyle]} />
      )}
      {!animate && (
        <Image source={{ uri: uri.static }} style={[styles.pictureWidth, pictureStyle]} />
      )}
    </IsHover>
  ) : (
    <>
      <Image source={{ uri: uri.static }} style={[styles.pictureWidth, pictureStyle]} />
    </>
  );

  if (onPress) {
    return (
      <Pressable
        onPress={onPress}
        style={[
          styles.containerStyle,
          {
            backgroundColor: selected ? colors.notification : 'none',
          },
          containerStyle,
        ]}>
        {content}
      </Pressable>
    );
  } else {
    return (
      <View
        style={[
          styles.containerStyle,
          {
            backgroundColor: selected ? colors.notification : 'none',
          },
          containerStyle,
        ]}>
        {content}
      </View>
    );
  }
}
