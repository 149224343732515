import { useScreenWidth } from '@Src/utils/hooks/screenWidth';
import responsive from '@Src/utils/responsive';
import { StyleSheet } from 'react-native';

export default function useGameInProgressQuestionStyle() {
  const screenWidth = useScreenWidth();

  const styles = StyleSheet.create({
    infoTop: {
      flexDirection: 'row',
    },
    question: {
      textAlign: 'center',
      paddingVertical: responsive(screenWidth, 10, 20, 40),
      userSelect: 'none',
    },
    questionCounter: {
      fontWeight: 'bold',
      textAlign: 'left',
      width: '100%',
    },
    joinMessageContainer: {
      flex: 1,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    joinCounterContainer: {
      paddingBottom: 20,
    },
    joinTextInfo: {
      textAlign: 'center',
    },
    categorieText: {
      textAlign: 'center',
      width: '100%',
    },
    difficultyText: {
      textAlign: 'right',
      width: '100%',
    },
  });
  return styles;
}
