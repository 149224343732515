import { PrimaryButton } from '@Src/components/Buttons';
import Modal from '@Src/components/Modal';
import Text from '@Src/components/Text';
import userState from '@Src/global/userState';
import useOAuthTwitch from '@Src/utils/hooks/oAuthTwitch';
import { capitalizeFirstLetter } from '@Src/utils/text';
import { get, post } from '@Src/utils/wrappedFetch';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import { BadgeTypes, EmoteProps, ListRewards, Reward, TwitchInfo } from '@squiz/shared';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { useRecoilValue } from 'recoil';

import Emote from '../Emote';
import useCustomRewardStyle from './CustomRewardStyle';

type StreamerProps = {
  emotes: EmoteProps;
  twitchToken: TwitchInfo;
};

type ActiveReward = {
  id?: string;
  subscriptionId?: string;
  rewardId: string;
  emoteId: string;
};

export default function CustomReward({ emotes, twitchToken }: StreamerProps) {
  const user = useRecoilValue(userState);
  const { colors } = useTheme();
  const [isRewardModalVisible, setIsRewardModalVisible] = useState(false);
  const [twitchAccessEnabled, setTwitchAccessEnabled] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [activeCustomRewards, setActiveCustomRewards] = useState<ActiveReward[]>([]);
  const [newCustomReward, setNewCustomReward] = useState<ActiveReward>({
    rewardId: '',
    emoteId: '',
  });
  const [customRewards, setCustomRewards] = useState<Reward>();

  const styles = useCustomRewardStyle();
  const { promptAsync, loading } = useOAuthTwitch(() => {}, [
    'user:read:email',
    'user:read:subscriptions',
    'channel:read:redemptions',
  ]);

  async function fetchCreatedReward() {
    if (!user.token || !emotes || !twitchToken) return;
    try {
      const { rewardBadges, subscriptions } = await get<ListRewards>({
        path: `badges?type=${BadgeTypes.Reward}`,
        token: user.token,
      });
      const rewards = subscriptions
        .map(({ id, condition }) => {
          const reward = rewardBadges.find(({ rewardId }) => condition.reward_id === rewardId);
          if (!reward) return null;
          return {
            id: reward!.id,
            subscriptionId: id,
            rewardId: reward!.rewardId,
            emoteId: reward!.source,
          };
        })
        .filter((reward) => reward);
      // @ts-ignore
      setActiveCustomRewards(rewards);
      // @ts-ignore
      fetchCustomRewards(rewards);
    } catch (error) {}
  }

  useEffect(() => {
    fetchCreatedReward();
  }, [user, emotes, twitchToken]);

  async function fetchCustomRewards(activeRewards: ActiveReward[]) {
    if (!twitchToken) return;
    try {
      const url =
        process.env.TWITCH_API_URL +
        `/channel_points/custom_rewards?broadcaster_id=${twitchToken.twitchId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${twitchToken.twitchToken}`,
          'Client-Id': process.env.TWITCH_CLIENT_ID!,
          'content-type': 'application/json',
        },
      });
      if (!response.ok) {
        setTwitchAccessEnabled(false);
      } else {
        setTwitchAccessEnabled(true);
        const result = (await response.json()) as Reward;
        const activeRewardIds = activeRewards.map(({ rewardId }) => rewardId);
        result.data = result.data.filter(
          ({ cost, id }) => cost >= 30000 && !activeRewardIds.includes(id)
        );
        setCustomRewards(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCustomReward(title: string, animated: boolean) {
    if (!user.token) return;
    setRequestInProgress(true);
    setIsRewardModalVisible(false);
    const { emoteId: source, ...customReward } = newCustomReward;
    try {
      await post({
        path: 'badges',
        body: {
          title,
          type: BadgeTypes.Reward,
          animated,
          source,
          ...customReward,
        },
        token: user.token,
      });
      fetchCreatedReward();
    } catch (error) {}
    setNewCustomReward({ emoteId: '', rewardId: '' });
    setRequestInProgress(false);
  }
  async function editActiveReward(reward: ActiveReward) {
    setIsRewardModalVisible(true);
    setNewCustomReward(reward);
  }

  const newCustomRewardData = emotes?.data?.find(({ id }) => id === newCustomReward.emoteId);

  const customRewardContent = (
    <>
      <View style={styles.container}>
        <View style={styles.emoteContainer}>
          {activeCustomRewards.map((reward) => {
            const customReward = customRewards?.data.find(({ id }) => id === reward.rewardId)!;
            const emote = emotes.data.find(({ id }) => id === reward.emoteId)!;
            if (!customReward || !emote) return;
            const customRewardImg = customReward.image
              ? customReward.image.url_4x
              : customReward.default_image.url_4x;
            const animatedEmote =
              emote.format.length > 1
                ? `https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/animated/dark/3.0`
                : undefined;
            return (
              <View key={customReward.id} style={styles.customRewardContainer}>
                <Pressable
                  onPress={() => editActiveReward(reward)}
                  style={styles.customRewardButton}>
                  <View style={styles.customRewardButtonContent}>
                    <Emote
                      uri={{
                        static: customRewardImg,
                      }}
                      pictureStyle={styles.customRewardImg}
                      containerStyle={styles.customRewardImgContainer}
                    />
                    <Emote
                      uri={{
                        static: emote.images.url_4x,
                        animated: animatedEmote,
                      }}
                      pictureStyle={{ width: 30, height: 30 }}
                      containerStyle={{ margin: 0 }}
                    />
                  </View>
                  <Text>{capitalizeFirstLetter(customReward.title)}</Text>
                </Pressable>
              </View>
            );
          })}
        </View>
        {activeCustomRewards.length < 2 && (
          <>
            {!customRewards || customRewards.data.length <= 0 ? (
              <Text>Aucune récompense sur votre chaîne ne remplit les conditions ci-dessus</Text>
            ) : (
              <Pressable
                onPress={() => setIsRewardModalVisible(true)}
                style={styles.addCustomRewardButton}>
                <FontAwesome5 name="plus-square" size={24} color="white" />
                <Text style={styles.addCustomRewardText}>Ajouter une récompense</Text>
              </Pressable>
            )}
          </>
        )}
      </View>
      <Modal setVisible={setIsRewardModalVisible} visible={isRewardModalVisible}>
        <View>
          <ScrollView style={{ maxHeight: 450 }}>
            <Text fontFamily="title" fontSize="xxl">
              Quelle récompense veux-tu lier ?
            </Text>
            <View style={styles.modalRewardContainer}>
              {customRewards?.data.map((customReward, key) => (
                <View key={customReward.id + key} style={styles.modalRewardItem}>
                  <Pressable
                    key={customReward.id}
                    onPress={() =>
                      setNewCustomReward({ ...newCustomReward, rewardId: customReward.id })
                    }
                    style={[
                      styles.modalRewardButton,
                      {
                        backgroundColor:
                          customReward.id === newCustomReward.rewardId
                            ? colors.notification
                            : 'gray',
                      },
                    ]}>
                    <Emote
                      selected={false}
                      uri={{
                        static: customReward.image
                          ? customReward.image.url_4x
                          : customReward.default_image.url_4x,
                      }}
                      pictureStyle={styles.modalRewardImg}
                      containerStyle={styles.modalRewardContainerImg}
                    />
                    <Text>{capitalizeFirstLetter(customReward.title)}</Text>
                  </Pressable>
                </View>
              ))}
            </View>
            <Text fontFamily="title" fontSize="xxl">
              Quel badge le viewer va obtenir ?
            </Text>
            <View style={styles.emoteContainer}>
              {emotes.data.map((emote) => (
                <View key={emote.id}>
                  <Emote
                    onPress={() => setNewCustomReward({ ...newCustomReward, emoteId: emote.id })}
                    selected={emote.id === newCustomReward.emoteId}
                    uri={{
                      static: `https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/static/dark/3.0`,
                      animated:
                        emote.format.length > 1
                          ? `https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/animated/dark/3.0`
                          : undefined,
                    }}
                  />
                </View>
              ))}
            </View>
          </ScrollView>
          <View style={styles.modalRewardRecap}>
            {newCustomReward.rewardId && newCustomReward.emoteId && (
              <>
                <View style={styles.modalRewardRecapContent}>
                  <Text style={styles.modalRewardRecapText}>
                    Quand un joueur va acheter la récompense "
                    {customRewards?.data.find(({ id }) => id === newCustomReward.rewardId)?.title}"
                    il obtiendra ce badge
                  </Text>
                  <Emote
                    uri={{
                      static: `https://static-cdn.jtvnw.net/emoticons/v2/${
                        emotes.data.find(({ id }) => id === newCustomReward.emoteId)?.id
                      }/static/dark/3.0`,
                      animated:
                        newCustomRewardData!.format?.length > 1
                          ? `https://static-cdn.jtvnw.net/emoticons/v2/${
                              emotes.data.find(({ id }) => id === newCustomReward.emoteId)?.id
                            }/animated/dark/3.0`
                          : undefined,
                    }}
                  />
                </View>
                <PrimaryButton
                  onPress={() =>
                    updateCustomReward(
                      newCustomRewardData!.name,
                      newCustomRewardData!.format?.length > 1
                    )
                  }>
                  <Text>{newCustomReward.subscriptionId ? 'Editer' : 'Valider'}</Text>
                </PrimaryButton>
              </>
            )}
          </View>
        </View>
      </Modal>
    </>
  );

  return (
    <>
      <Text fontFamily="title" fontSize="xxl">
        RÉCOMPENSES
      </Text>
      <View style={styles.separator}>
        <Text>
          Les récompenses permettent d'apporter plus d'intérêt aux points de chaîne Twitch.
        </Text>
        <Text>
          Pour utiliser cette fonctionnalité tu dois avoir créé une ou plusieurs récompense(s) sur
          ta chaîne Twitch.
        </Text>
        <Text>Une fois ces récompenses crées tu peux en lier un maximum de deux à sQuiz.</Text>
        <Text>
          Chaque viewer qui achètera une de ces récompenses sur ta chaîne Twitch recevra une emote
          que tu as choisi sur sQuiz.
        </Text>
        <Text>
          Afin de garder une certaine rareté pour ces badges la récompense Twitch doit avoir un prix
          supérieur ou égal à 30000 points de chaîne.
        </Text>
      </View>
      {loading || requestInProgress ? (
        <View style={styles.container}>
          <View style={styles.emoteContainer}>
            <ActivityIndicator />
          </View>
        </View>
      ) : (
        <>
          {twitchAccessEnabled ? (
            customRewardContent
          ) : (
            <View style={styles.container}>
              <View style={styles.emoteContainer}>
                <TouchableOpacity onPress={() => promptAsync()} style={styles.twitchButton}>
                  <Text style={styles.twitchButtonText}>
                    Autoriser sQuiz à voir mes récompenses
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </>
      )}
    </>
  );
}
