import Text from '@Src/components/Text';
import { getMedalWithRank } from '@Src/utils/medals';
import React from 'react';
import { View, Image } from 'react-native';

import useMedalStyle from './MedalStyle';

type MedalProps = {
  rank: number;
};

export default function Medal({ rank }: MedalProps) {
  const styles = useMedalStyle();
  const image = getMedalWithRank(rank);

  return (
    <Text fontSize="xl" style={styles.textContainer}>
      {image ? <Image source={image} style={styles.medal} /> : <View style={styles.dot} />}
    </Text>
  );
}
