import { useScreenWidth } from '@Src/utils/hooks/screenWidth';
import responsive from '@Src/utils/responsive';
import { useTheme } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

export default function useStaffStyle() {
  const { colors } = useTheme();
  const screenWidth = useScreenWidth();

  const styles = StyleSheet.create({
    container: {
      minHeight: 1, // Header component need a height to be display
      paddingTop: 2,
      alignItems: 'center',
      borderBottomWidth: 2,
      borderBottomColor: colors.border,
      backgroundColor: colors.primary,
    },
    scrollView: {
      height: responsive(screenWidth, '80vh', '80vh', '70vh'),
    },
    title: {
      paddingBottom: 15,
    },
    staffContainer: {
      width: responsive(screenWidth, '90vw', '80vw', '50vw'),
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    staffMemberContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: 250,
    },
    staffPicture: {
      width: 62,
      height: 62,
      borderRadius: 50,
    },
    staffName: {
      paddingTop: 8,
    },
    staffDescription: {
      width: '100%',
      textAlign: 'center',
    },
    button: {
      borderRadius: 5,
      padding: 5,
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 5,
    },
    staffLinkDesktop: {
      display: responsive(screenWidth, 'none', 'flex', 'flex'),
    },
    staffLinkMobile: {
      display: responsive(screenWidth, 'flex', 'none', 'none'),
    },
  });
  return styles;
}
