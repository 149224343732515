import { ResponsiveContainer } from '@Src/components/Containers';
import Text from '@Src/components/Text';
import { useTheme } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React from 'react';
import { Platform, Pressable, View } from 'react-native';

import styles from './FAQStyle';

export default function FAQ() {
  const { colors } = useTheme();

  function openLink(link: string) {
    if (Platform.OS === 'web') {
      window.open(link, '_blank');
    } else {
      Linking.openURL(link);
    }
  }
  return (
    <ResponsiveContainer>
      <View style={{ flex: 1 }}>
        <Text fontSize="xl" style={styles.question}>
          Où puis-je signaler un bug ?
        </Text>
        <Text fontSize="lg">
          Un canal dédié a été créé sur
          <Pressable onPress={() => openLink('https://discord.gg/a2S5uzrMX7')}>
            <Text fontSize="lg" style={{ color: colors.notification }}>
              {' '}
              Discord
            </Text>
          </Pressable>
          .
        </Text>
        <Text fontSize="xl" style={styles.question}>
          Comment puis-je proposer des questions ?
        </Text>
        <Text fontSize="lg">
          Vous pouvez proposer vos propres questions en utilisant{' '}
          <Pressable
            onPress={() =>
              openLink(
                'https://docs.google.com/forms/d/e/1FAIpQLSdwMnH332LgNZrsfKjOCQThjZwA9CvlJ4XS5BBSrcUFsNVZJA/viewform'
              )
            }>
            <Text fontSize="lg" style={{ color: colors.notification }}>
              ce formulaire
            </Text>
          </Pressable>
          .
        </Text>
        <Text fontSize="xl" style={styles.question}>
          Est-ce que je peux aider pour le développement du site ?
        </Text>
        <Text fontSize="lg">
          La meilleure façon d'aider est de proposer vos idées, faire connaître le site, etc.
        </Text>
        <Text fontSize="xl" style={styles.question}>
          J'ai terminé une partie et je n'ai pas gagné d'expérience, pourquoi ?
        </Text>
        <Text fontSize="lg">
          Pour éviter les abus, il faut un minimum de 5 personnes et ne pas être en difficulté
          aléatoire pour que de l'expérience soit acquise sur une partie.
        </Text>
        <Text fontSize="xl" style={styles.question}>
          J'ai réussi un défi et je n'ai pas gagné de trophée, pourquoi ?
        </Text>
        <Text fontSize="lg">
          Pour éviter les abus, il faut être en partie publique pour pouvoir débloquer les trophées
          et un minimum de 5 personnes pour les défis "victoires" et "points".
        </Text>
        <Text fontSize="xl" style={styles.question}>
          J'ai terminé une partie et mes statistiques n'ont pas évolué, pourquoi ?
        </Text>
        <Text fontSize="lg">
          Pour que vos statistiques des parties et des questions soient comptabilisées il faut un
          minimum de 5 personnes et ne pas être en difficulté aléatoire. D'autre part, pour les
          statistiques par catégories il faut un minimum de 5 personnes et être en partie publique.
        </Text>
        <Text fontSize="xl" style={styles.question}>
          Je me suis fait bannir, comment faire pour être déban ?
        </Text>
        <Text fontSize="lg">
          Merci de faire votre demande déban sur{' '}
          <Pressable onPress={() => openLink('https://forms.gle/gwXjh4T6otpRmM6GA')}>
            <Text fontSize="lg" style={{ color: colors.notification }}>
              ce formulaire
            </Text>
          </Pressable>
          .
        </Text>
        <Text fontSize="xl" style={styles.question}>
          Pour toutes autres questions, merci de nous contacter via{' '}
          <Pressable onPress={() => openLink('https://discord.gg/xssGz5xu3e')}>
            <Text fontSize="xl" style={{ color: colors.notification }}>
              Discord
            </Text>
          </Pressable>{' '}
          ou sur notre adresse mail: support@squiz.gg
        </Text>
      </View>
    </ResponsiveContainer>
  );
}
