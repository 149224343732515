import { BadgeTypes } from '@squiz/shared';
import React, { useState } from 'react';
import { View, StyleProp, Image, ImageStyle } from 'react-native';

import { IsHover } from '../Hover';
import Text from '../Text';
import useBadgeStyle from './BadgeStyle';

type Props = {
  badgeName?: string;
  imageStyle?: StyleProp<ImageStyle>;
  locked?: boolean;
  overForInfo?: boolean;
  source?: string;
  lockedDescription?: string;
  type?: BadgeTypes;
  animated?: boolean;
  top?: boolean;
};

export default function Badge({
  badgeName,
  imageStyle,
  source,
  locked = false,
  overForInfo = true,
  lockedDescription,
  type = BadgeTypes.Special,
  animated = false,
  top = true,
}: Props) {
  const [displayInfo, setDisplayInfo] = useState(!overForInfo);
  const [animate, setAnimate] = useState(false);
  const [displayDescription, setDisplayDescription] = useState(false);
  let formattedSource: string;
  let animatedFormattedSource: string = '';
  const styles = useBadgeStyle();

  if (type === BadgeTypes.Special) {
    formattedSource = `${window.location.origin}/badges/${source}.png`;
    if (animated) {
      animatedFormattedSource = `${window.location.origin}/badges/${source}.gif`;
    }
  } else {
    formattedSource = `https://static-cdn.jtvnw.net/emoticons/v2/${source}/static/dark/3.0`;
    if (animated) {
      animatedFormattedSource = `https://static-cdn.jtvnw.net/emoticons/v2/${source}/animated/dark/3.0`;
    }
  }

  if (!source) return <View style={imageStyle} />;

  function handleOver() {
    if (overForInfo) {
      setDisplayInfo(true);
    }
    if (lockedDescription) {
      setDisplayDescription(true);
    }
  }

  function handleLeaveOver() {
    if (overForInfo) {
      setDisplayInfo(false);
    }
    if (lockedDescription) {
      setDisplayDescription(false);
    }
  }

  function handleAnimate() {
    if (!locked) {
      setAnimate(true);
    }
  }

  function handleLeaveAnimate() {
    if (!locked) {
      setAnimate(false);
    }
  }

  const badge = animated ? (
    <IsHover onHover={handleAnimate} onLeaveOver={handleLeaveAnimate}>
      {animate && <Image source={{ uri: animatedFormattedSource }} style={[imageStyle]} />}
      {locked && (
        <Image
          source={{ uri: formattedSource }}
          style={[imageStyle, styles.pictureGray as ImageStyle]}
        />
      )}
      {!animate && (
        <Image
          source={{ uri: formattedSource }}
          style={[
            imageStyle,
            locked && (styles.pictureAbsolute as ImageStyle),
            locked && (styles.opacity as ImageStyle),
          ]}
        />
      )}
    </IsHover>
  ) : (
    <>
      {locked && (
        <Image
          source={{ uri: formattedSource }}
          style={[imageStyle, styles.pictureGray as ImageStyle]}
        />
      )}
      <Image
        source={{ uri: formattedSource }}
        style={[
          imageStyle,
          locked && (styles.pictureAbsolute as ImageStyle),
          locked && (styles.opacity as ImageStyle),
        ]}
      />
    </>
  );

  const labelPositionStyle = top
    ? { top: '120%', transform: 'translate(-50%, -50%)', left: '53%' }
    : { top: '70%', transform: 'translate(0%, -50%)', left: '100%' };

  const badgeAndInfos = (
    <>
      <View>
        {badge}
        {displayInfo && (
          // @ts-ignore
          <View style={[styles.labelContainer, labelPositionStyle]}>
            <View style={[styles.label]}>
              <Text style={styles.labelText} fontSize="sm">
                {badgeName}
              </Text>
            </View>
          </View>
        )}
      </View>
      {displayDescription && locked && lockedDescription && (
        <View style={[styles.lockedDescriptionContainer]}>
          <View style={[styles.lockedDescription]}>
            <Text fontSize="sm">{lockedDescription}</Text>
          </View>
        </View>
      )}
    </>
  );

  if (!locked && !overForInfo) {
    return <>{badgeAndInfos}</>;
  } else {
    return (
      <IsHover onHover={handleOver} onLeaveOver={handleLeaveOver}>
        {badgeAndInfos}
      </IsHover>
    );
  }
}
