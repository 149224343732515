import correctImage from '@Assets/images/answer/correct.png';
import incorrectImage from '@Assets/images/answer/failed.png';
import ReportButton from '@Src/components/QuestionReport';
import Text from '@Src/components/Text';
import { titleCase } from '@Src/utils/text';
import { FontAwesome5 } from '@expo/vector-icons';
import { GameRank } from '@squiz/shared';
import React from 'react';
import { View, Image } from 'react-native';

import styles from './QuestionInfoStyle';

type QuestionInfosProps = {
  id: number;
  question: string;
  theme: string;
  answers?: string[];
  displayReport?: boolean;
  rank?: number;
};

export default function QuestionInfos({
  id,
  question,
  answers,
  theme,
  displayReport = false,
  rank,
}: QuestionInfosProps) {
  return (
    <View style={styles.roundContainer}>
      {rank != null && (
        <View style={styles.medalContainer}>
          <Image
            source={rank > GameRank.NotAnswered ? correctImage : incorrectImage}
            style={styles.medalHeight}
          />
        </View>
      )}
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{question}</Text>
        <Text style={styles.theme}>{titleCase(theme)}</Text>
        {answers && <Text>{answers.length > 1 ? answers.join(' / ') : answers}</Text>}
      </View>
      {displayReport && (
        <View style={styles.reportContainer}>
          <ReportButton id={id} question={question} answers={answers} theme={theme} />
          <FontAwesome5
            onPress={() => window.open('http://google.com/search?q=' + question, '_blank')}
            style={{ paddingTop: 5 }}
            name="search"
            size={18}
            color="white"
          />
        </View>
      )}
    </View>
  );
}
