import { StyleSheet } from 'react-native';

export default function useCheckBoxesStyle() {
  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
}
