import Card from '@Src/components/Card/Card';
import Text from '@Src/components/Text';
import questionState from '@Src/global/Room/question';
import roomInfosState from '@Src/global/Room/roomInfos';
import isQuestionTimeState from '@Src/global/isQuestionTimeState';
import roomSocketState from '@Src/global/roomSocket';
import { useSound } from '@Src/utils/hooks/sound';
import useListener from '@Src/utils/hooks/useListener';
import { useTheme } from '@react-navigation/native';
import { GameEvent, parseAnswer } from '@squiz/shared';
import React, { useState, createRef, useEffect } from 'react';
import { TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { useRecoilValue } from 'recoil';

import { GuessStatus } from '../../GameInProgress/GameInProgress';
import GameTimer from '../GameTimer';
import Life from '../Life';
import styles from './GameInputStyle';
import useCheatTab from './useCheatTab';

type GameInputProps = {
  handleGuess: (guess: string, guessStatus: GuessStatus) => void;
  wrongGuess: () => void;
  nearGuess: () => void;
};

export default function GameInput({ handleGuess, wrongGuess, nearGuess }: GameInputProps) {
  const [playerAnswer, setPlayerAnswer] = useState('');
  const [cheating, setCheating] = useState(false);
  const roomSocket = useRecoilValue(roomSocketState);
  const question = useRecoilValue(questionState);
  const isQuestionTime = useRecoilValue(isQuestionTimeState);
  const roomInfos = useRecoilValue(roomInfosState);
  const { colors } = useTheme();
  const inputRef = createRef<TextInput>();
  const wrongSound = useSound({ source: require('@Assets/sounds/wrong.mp3') });
  const nearSound = useSound({ source: require('@Assets/sounds/near.mp3') });
  const [life, setLife] = useState(4);
  useListener(GameEvent.WrongAnswer, wrongAnswer);
  useListener(GameEvent.NearAnswer, nearAnswer);
  useCheatTab(() => setCheating(true));

  useEffect(() => {
    if (!question) return;
    setLife(question.maxNumberOfGuesses);
    setCheating(false);
    inputRef.current?.focus();
    setPlayerAnswer('');
  }, [question]);

  function wrongAnswer() {
    wrongSound?.play();
    setLife(life - 1);
    wrongGuess();
  }

  function nearAnswer() {
    nearSound?.play();
    setLife(life - 1);
    nearGuess();
  }

  function emitAnswer() {
    if (roomSocket && isQuestionTime && life > 0 && playerAnswer.length > 0) {
      handleGuess(playerAnswer, GuessStatus.Waiting);
      const parsedAnswer = parseAnswer(playerAnswer);
      roomSocket.emit('guess', parsedAnswer);
      setPlayerAnswer('');
    }
  }

  async function checkKey(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault();
      emitAnswer();
    }
  }

  if (cheating) {
    return (
      <Text style={[styles.cheatText]}>
        Le changement de fenêtre ou le copier-coller sont interdits dans ce salon. Veuillez attendre
        la prochaine question.
      </Text>
    );
  }

  return (
    <Card style={styles.container}>
      <TextInput
        ref={inputRef}
        value={playerAnswer}
        onChangeText={(text) =>
          playerAnswer.length + 1 >= text.length
            ? setPlayerAnswer(text)
            : roomInfos?.checkForCheat === true
            ? setCheating(true)
            : null
        }
        onKeyPress={(e) => checkKey(e)}
        multiline={false}
        style={[styles.input, { color: colors.text }]}
        autoFocus
      />
      <GameTimer />
      <Life life={life} />
    </Card>
  );
}
