import { HoverStyle } from '@Src/components/Hover';
import staff from '@Src/utils/staff';
import { useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, Image, ScrollView, Pressable } from 'react-native';

import Text from '../../Text';
import Modal from '../Modal';
import useStaffStyles from './StaffStyle';

export default function Staff() {
  const [displayStaff, setDisplayStaff] = useState(false);
  const styles = useStaffStyles();
  const { colors } = useTheme();

  return (
    <>
      <Pressable
        onPress={() => setDisplayStaff(true)}
        style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <HoverStyle
          style={[styles.button, { borderColor: colors.border }]}
          onHover={{ backgroundColor: colors.border }}>
          <Text fontSize="lg" style={styles.staffLinkDesktop}>
            Développé par la team sQuiz
          </Text>
          <Text fontSize="lg" style={styles.staffLinkMobile}>
            Team sQuiz
          </Text>
        </HoverStyle>
        <Text fontSize="lg">|</Text>
      </Pressable>
      <Modal visible={displayStaff} setVisible={setDisplayStaff}>
        <ScrollView style={styles.scrollView}>
          <View style={styles.title}>
            <Text fontFamily="title" fontSize="xxl" style={{ textAlign: 'center' }}>
              Les merveilleuses personnes derrière sQuiz ❤️
            </Text>
          </View>
          <View style={styles.staffContainer}>
            {staff.map(({ avatar, name, description }) => (
              <View key={name} style={styles.staffMemberContainer}>
                <Image source={avatar} style={styles.staffPicture} />
                <Text fontFamily="title" fontSize="lg" style={styles.staffName}>
                  {name}
                </Text>
                <Text fontSize="sm" style={styles.staffDescription}>
                  {description}
                </Text>
              </View>
            ))}
          </View>
        </ScrollView>
      </Modal>
    </>
  );
}
