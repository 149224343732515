import Modal from '@Src/components/Modal';
import Text from '@Src/components/Text';
import userState from '@Src/global/userState';
import { get, post } from '@Src/utils/wrappedFetch';
import { FontAwesome5 } from '@expo/vector-icons';
import { BadgeTypes, EmoteData, EmoteProps } from '@squiz/shared';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import { useRecoilValue } from 'recoil';

import Emote from '../Emote';
import useSubStyle from './SubStyle';

type SubProps = {
  emotes: EmoteProps;
};

export default function Sub({ emotes }: SubProps) {
  const user = useRecoilValue(userState);
  const [isSubModalVisible, setIsSubModalVisible] = useState(false);
  const [subBadge, setSubBadge] = useState<EmoteData>();
  const [isLoading, setIsLoading] = useState(true);

  const styles = useSubStyle();

  async function fetchSubBadge() {
    if (!user.token || !emotes) return;
    try {
      const response = await get<{ source: string }[]>({
        path: `badges?type=${BadgeTypes.Sub}`,
        token: user.token,
      });
      const subBadgeId = response.length > 0 ? response[0].source : undefined;
      const subBadge = emotes?.data.find(({ id }) => id === subBadgeId);
      setSubBadge(subBadge);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSubBadge();
  }, [user, emotes]);

  async function updateSubBadge(emoteId: string, animated: boolean) {
    if (!user.token) return;
    setIsLoading(true);
    setIsSubModalVisible(false);
    const subBadge = emotes?.data.find(({ id }) => id === emoteId);
    if (!subBadge) return;
    setSubBadge(subBadge);
    try {
      await post({
        path: 'badges',
        body: {
          type: BadgeTypes.Sub,
          source: emoteId,
          animated,
        },
        token: user.token,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  return (
    <>
      <Text fontFamily="title" fontSize="xxl">
        BADGE SUB
      </Text>
      <View style={styles.separator}>
        <Text>
          Ce badge pourra être équipé depuis la page de profil uniquement par les joueurs qui sont
          sub à ta chaîne twitch.
        </Text>
        <Text>Si le sub d'un joueur expire le badge lui sera automatiquement retiré.</Text>
      </View>

      <View style={styles.emoteContainer}>
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            {subBadge ? (
              <Emote
                onPress={() => setIsSubModalVisible(true)}
                selected={false}
                uri={{
                  static: `https://static-cdn.jtvnw.net/emoticons/v2/${subBadge.id}/static/dark/3.0`,
                  animated:
                    subBadge.format.length > 1
                      ? `https://static-cdn.jtvnw.net/emoticons/v2/${subBadge.id}/animated/dark/3.0`
                      : undefined,
                }}
              />
            ) : (
              <Pressable onPress={() => setIsSubModalVisible(true)} style={styles.addSubButton}>
                <FontAwesome5 name="plus-square" size={24} color="white" />
                <Text style={styles.addSubText}>Ajouter un badge pour mes subs</Text>
              </Pressable>
            )}
          </>
        )}
      </View>
      <Modal setVisible={setIsSubModalVisible} visible={isSubModalVisible}>
        <Text fontFamily="title" fontSize="xxl">
          Quel badge veux-tu attribuer à tes subs ?
        </Text>
        <View style={styles.emoteContainer}>
          {emotes.data.map((emote) => (
            <View key={emote.id}>
              <Emote
                onPress={() => updateSubBadge(emote.id, emote.format.length > 1)}
                selected={false}
                uri={{
                  static: `https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/static/dark/3.0`,
                  animated:
                    emote.format.length > 1
                      ? `https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/animated/dark/3.0`
                      : undefined,
                }}
              />
            </View>
          ))}
        </View>
      </Modal>
    </>
  );
}
