import userBasicInfoState from '@Src/global/userBasicInfos';
import userState from '@Src/global/userState';
import { isSubProcess } from '@Src/utils/twitch';
import { get, put } from '@Src/utils/wrappedFetch';
import { BadgeTypes, ShowBadge, TwitchInfo } from '@squiz/shared';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useRecoilState, useRecoilValue } from 'recoil';

import LockedBadge from '../../LockedBadge';
import { DisplayBadge } from '../Badges';

type RewardBadgesProps = {
  handlePress: (badge: ShowBadge) => void;
  badges: DisplayBadge[];
};

export default function SubBadges({ handlePress, badges }: RewardBadgesProps) {
  const [userBasicInfos, setUserBasicInfos] = useRecoilState(userBasicInfoState);
  const user = useRecoilValue(userState);
  const [subList, setSubList] = useState<{ badgeId: number; isSub: boolean }[]>([]);
  const [twitchToken, setTwitchToken] = useState<TwitchInfo>();

  async function fetchUnlockedBadges() {
    if (!userBasicInfos || !user.token || !twitchToken) return;
    const subs = [];
    for (const i in badges) {
      const { broadcasterId, id } = badges[i];
      const isSub = await isSubProcess(
        broadcasterId!,
        user.token,
        twitchToken.twitchToken as string,
        twitchToken.twitchId as string
      );
      // If the user is not sub anymore but still have the badge
      if (isSub === false && userBasicInfos.badge?.id === id) {
        put({
          path: 'me-edit',
          token: user.token,
          body: { badge: { type: BadgeTypes.Default, id: 1 } },
        });
        setUserBasicInfos({ ...userBasicInfos, badge: undefined });
      }
      subs.push({ badgeId: id, isSub });
    }
    setSubList(subs);
  }

  async function fetchTwitchToken() {
    if (!userBasicInfos || !user.token) return;
    try {
      const twitchToken = await get<TwitchInfo>({ path: 'twitch-token', token: user.token });
      if (!twitchToken) return;
      setTwitchToken(twitchToken);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!twitchToken || !twitchToken.twitchToken) return;
    fetchUnlockedBadges();
  }, [twitchToken]);

  useEffect(() => {
    if (!userBasicInfos) return;
    fetchTwitchToken();
  }, [userBasicInfos]);

  if (!userBasicInfos) return null;

  return (
    <>
      {badges.map((sub) => {
        return (
          <View key={sub.id}>
            <LockedBadge
              onPress={() => handlePress(sub)}
              selected={userBasicInfos.badge?.id === sub.id}
              name={sub.title}
              source={sub.source}
              animated={sub.animated}
              type={sub.type}
              lock={!subList.find(({ badgeId, isSub }) => badgeId === sub.id && isSub)}
            />
          </View>
        );
      })}
    </>
  );
}
