import { useScreenWidth } from '@Src/utils/hooks/screenWidth';
import responsive from '@Src/utils/responsive';
import { StyleSheet } from 'react-native';

export default function useStatsStyle() {
  const screenWidth = useScreenWidth();

  return StyleSheet.create({
    emoteContainer: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 80,
      paddingTop: 20,
      paddingBottom: 20,
    },
    container: {
      paddingTop: 40,
      paddingBottom: 40,
      width: responsive(screenWidth, '90%', '80%', '70%'),
      maxWidth: 1300,
      alignSelf: 'center',
    },
    profilePicContainer: {
      alignItems: 'center',
      paddingVertical: 10,
    },
    profilePic: {
      width: 80,
      height: 80,
      borderRadius: 50,
    },
    panelInfoContainer: {
      paddingTop: 20,
      paddingBottom: 30,
      textAlign: 'center',
    },
    separator: {
      paddingTop: 10,
    },
  });
}
