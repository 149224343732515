export type Difficulty = {
  id: number;
  title: string;
  color: [string, string];
  xpMultiplier: number;
};

enum DifficultyEnum {
  Random = -1,
  Beginner = 1,
  Intermediate = 2,
  Expert = 3,
  Unknown = 4,
}

export default DifficultyEnum;

export const Difficulties: Difficulty[] = [
  {
    id: DifficultyEnum.Beginner,
    title: 'Initié',
    color: ['#a8e063', '#56ab2f'],
    xpMultiplier: 1,
  },
  {
    id: DifficultyEnum.Intermediate,
    title: 'Confirmé',
    color: ['#ca6642', '#da9f48'],
    xpMultiplier: 2,
  },
  {
    id: DifficultyEnum.Expert,
    title: 'Expert',
    color: ['#c04572', '#dc6747'],
    xpMultiplier: 3,
  },
  {
    id: DifficultyEnum.Unknown,
    title: 'Inconnu',
    color: ['#a8e063', '#56ab2f'],
    xpMultiplier: 1,
  },
  {
    id: DifficultyEnum.Random,
    title: 'Aléatoire',
    color: ['#a8e063', '#56ab2f'],
    xpMultiplier: 1,
  },
];

export function GetDifficultyFromId(difficultyId: DifficultyEnum): Difficulty {
  const difficulty = Difficulties.find((difficulty) => difficulty.id === difficultyId);
  if (!difficulty) {
    return Difficulties[0];
  }
  return difficulty;
}

export function GetDifficultyFromName(difficultyName: string): Difficulty {
  let difficulty = Difficulties.find((difficulty) => difficulty.title === difficultyName);
  if (!difficulty) {
    difficulty = Difficulties[0];
  }
  return difficulty;
}

export function GetDifficultyIdArrayFromDifficultyArray(difficulty: Difficulty[]): number[] {
  const difficultyArrayId: number[] = [];
  difficulty.map((name) => {
    difficultyArrayId.push(Difficulties.find((difficulty) => difficulty?.id === name?.id)!.id);
  });
  if (difficulty.length === 0) {
    difficultyArrayId.push(DifficultyEnum.Beginner);
  }
  return difficultyArrayId;
}

export function GetDifficultyTitleArrayFromName(difficultyArray: Difficulty[]): string[] {
  const difficulty: string[] = [];
  difficultyArray.map((difficultyMember) => {
    difficulty.push(
      Difficulties.find((difficulty) => difficulty.title === difficultyMember.title)!.title
    );
  });
  if (difficulty.length === 0) {
    difficulty.push(Difficulties[0].title);
  }
  return difficulty;
}

export function GetDifficultyIdArrayFromTitleArray(difficultyArray: number[]): string[] {
  const difficulty: string[] = [];
  difficultyArray.map((difficultyMember) => {
    difficulty.push(Difficulties.find((difficulty) => difficulty.id === difficultyMember)!.title);
  });
  if (difficulty.length === 0) {
    difficulty.push(Difficulties[0].title);
  }
  return difficulty;
}

export function GetDifficultyArrayFromNameArray(difficultyArray: string[]): Difficulty[] {
  const difficulty: Difficulty[] = [];
  difficultyArray.map((difficultyMember) => {
    difficulty.push(Difficulties.find((difficulty) => difficulty?.title === difficultyMember)!);
  });
  if (difficulty.length === 0) {
    difficulty.push(Difficulties[0]);
  }
  return difficulty;
}
