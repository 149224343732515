import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    width: 10,
    height: 40,
    paddingLeft: 5,
  },
});

export default styles;
