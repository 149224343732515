import { HoverStyle } from '@Src/components/Hover';
import Text from '@Src/components/Text';
import { FontAwesome5 } from '@expo/vector-icons';
import { SQuizStreamData } from '@squiz/shared';
import React from 'react';
import { View, ScrollView, Pressable, Platform, Linking, ImageBackground } from 'react-native';

import useStreamsStyle from './StreamStyle';

type StreamsProps = {
  streams: SQuizStreamData[];
};

export default function Streams({ streams }: StreamsProps) {
  const styles = useStreamsStyle();

  function openStream(stream: string) {
    if (Platform.OS === 'web') {
      window.open('https://www.twitch.tv/' + stream, '_blank');
    } else {
      Linking.openURL('https://www.twitch.tv/' + stream);
    }
  }

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.streamsContainer}>
        {streams.map((stream) => (
          <View key={stream.userName} style={styles.streamCard}>
            <HoverStyle style={styles.cardBorder} onHover={styles.cardHover}>
              <Pressable onPress={() => openStream(stream.userName)}>
                <ImageBackground
                  source={{
                    uri: `https://static-cdn.jtvnw.net/previews-ttv/live_user_${stream.userName.toLocaleLowerCase()}-330x186.jpg`,
                  }}
                  style={styles.image}>
                  <View style={styles.cardViewerCount}>
                    <FontAwesome5 name="user" size={15} color="#9146FF" />
                    <Text style={styles.cardViewerCountText}>{stream.viewerCount}</Text>
                  </View>
                </ImageBackground>
              </Pressable>
            </HoverStyle>
            <Text style={styles.text}>{stream.userName}</Text>
          </View>
        ))}
      </View>
    </ScrollView>
  );
}
