import Badge from '@Src/components/Badge';
import { Level } from '@Src/components/ExperienceBar';
import { HoverStyle } from '@Src/components/Hover';
import userBasicInfoState from '@Src/global/userBasicInfos';
import userState from '@Src/global/userState';
import DisconnectButton from '@Src/screens/Profile/Disconnect';
import avatars from '@Src/utils/loadAvatars';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTheme, useNavigation } from '@react-navigation/native';
import React, { useState, useEffect, useRef } from 'react';
import { View, Image, Pressable, TouchableOpacity } from 'react-native';
import { useRecoilValue } from 'recoil';

import Text from '../../Text';
import SoundVolume from '../soundVolume';
import styles, { useHeaderButtonStyle, useProfileDropDownRowStyle } from './ProfileStyle';

export default function Profile() {
  const { colors } = useTheme();
  const user = useRecoilValue(userState);
  const userBasicInfos = useRecoilValue(userBasicInfoState);
  const [displayDropDown, setDisplayDropDown] = useState(false);
  const buttonStyle = useHeaderButtonStyle();
  const dropDownStyle = useProfileDropDownRowStyle();

  const navigation = useNavigation();
  const profileButtonRef = useRef<any>(null);
  const dropDownRef = useRef<any>(null);

  useEffect(() => {
    if (!user.connected) {
      setDisplayDropDown(false);
    }
  }, [user.connected]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      const target = e.target as Node;

      if (
        dropDownRef.current &&
        profileButtonRef.current &&
        !dropDownRef.current.contains(target) &&
        !profileButtonRef.current.contains(target)
      ) {
        setDisplayDropDown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <View style={styles.container}>
      {user.connected && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {userBasicInfos && (
            <>
              <View style={styles.level}>
                <Level experience={userBasicInfos.experience} />
              </View>
              <Image
                source={avatars[userBasicInfos.avatar as keyof typeof avatars]}
                style={styles.avatar}
              />
              {userBasicInfos.badge && (
                <Badge
                  badgeName={userBasicInfos.badge.title}
                  source={userBasicInfos.badge.source}
                  animated={userBasicInfos.badge.animated}
                  type={userBasicInfos.badge.type}
                  imageStyle={styles.avatar}
                  top={false}
                />
              )}
            </>
          )}
          <Text>{user.username}</Text>
        </View>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <SoundVolume toggle={displayDropDown} />
        {user.connected && (
          <HoverStyle onHover={buttonStyle.buttonClicked}>
            <TouchableOpacity
              ref={profileButtonRef}
              onPress={() => setDisplayDropDown(!displayDropDown)}>
              <FontAwesome5
                name="user-alt"
                size={18}
                color={colors.text}
                style={[
                  buttonStyle.shared,
                  displayDropDown ? buttonStyle.buttonClicked : buttonStyle.button,
                ]}
              />
            </TouchableOpacity>
          </HoverStyle>
        )}
        {displayDropDown && (
          <View
            ref={dropDownRef}
            style={[styles.dropDownContainer, { borderColor: colors.border }]}>
            <View style={styles.dropDown}>
              {profileMenu.map(({ title, icon, route }) => (
                <HoverStyle onHover={dropDownStyle.rowHover}>
                  <View style={dropDownStyle.rowWrapper}>
                    <Pressable
                      key={title}
                      onPress={() => navigation.navigate(route)}
                      style={dropDownStyle.dropDownRow}>
                      <FontAwesome5 name={icon} size={15} color={colors.text} />
                      <Text style={dropDownStyle.dropDownText}>{title}</Text>
                    </Pressable>
                  </View>
                </HoverStyle>
              ))}
              <DisconnectButton />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const profileMenu = [
  { title: 'Personnalisation', icon: 'paint-brush', route: 'Profile' },
  { title: 'Statistiques', icon: 'chart-bar', route: 'Stats' },
  { title: 'Trophées', icon: 'trophy', route: 'Challenges' },
];
