import { StyleSheet } from 'react-native';

export default function useCustomRewardStyle() {
  return StyleSheet.create({
    emoteContainer: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      minHeight: 80,
      paddingVertical: 30,
    },
    customRewardContainer: {
      paddingHorizontal: 2,
      paddingVertical: 4,
      width: '20%',
      overflow: 'hidden',
    },
    customRewardButton: {
      alignItems: 'center',
      backgroundColor: 'gray',
      padding: 10,
      borderRadius: 5,
    },
    customRewardButtonContent: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customRewardImg: {
      width: 35,
      height: 35,
    },
    customRewardImgContainer: {
      margin: 0,
    },
    addCustomRewardText: {
      paddingTop: 8,
    },
    addCustomRewardButton: {
      alignItems: 'center',
      paddingTop: 10,
    },
    modalRewardContainer: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      paddingVertical: 20,
    },
    modalRewardItem: {
      paddingHorizontal: 2,
      paddingVertical: 4,
      overflow: 'hidden',
      width: '20%',
    },
    modalRewardButton: {
      alignItems: 'center',
      padding: 10,
      borderRadius: 5,
    },
    modalRewardImg: {
      width: 20,
      height: 20,
    },
    modalRewardContainerImg: {
      margin: 0,
    },
    modalRewardRecap: {
      alignItems: 'center',
    },
    modalRewardRecapContent: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalRewardRecapText: {
      fontWeight: 'bold',
    },
    twitchButton: {
      paddingHorizontal: 14,
      paddingVertical: 10,
      backgroundColor: '#6441a5',
      borderRadius: 5,
    },
    twitchButtonText: {
      fontWeight: 'bold',
    },
    separator: {
      paddingTop: 10,
    },
  });
}
